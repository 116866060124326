
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$adam-chappell-primary: mat.define-palette(mat.$orange-palette);
$adam-chappell-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$adam-chappell-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$adam-chappell-theme: mat.define-light-theme($adam-chappell-primary, $adam-chappell-accent, $adam-chappell-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($adam-chappell-theme);

/* You can add global styles to this file, and also import other style files */
@import './app/global-variables.scss';
@import './app/scss/mixins/text-glitch';
@import '../node_modules/bootstrap-4-grid/scss/grid';

body {
  background-color: $primary-colour;
  font-family: $default-body-font;
  font-display: swap;
}

@for $index from 1 through 6 {
  h#{$index}{
    font-family: $default-header-font;
    text-transform: uppercase;
    font-display: swap;
  }
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #0000004d;
  box-shadow: inset 0 0 6px #0000004d;
  background-color: #F5F5F5;
}

#body-container {
  position: fixed;
  width: 96vw;
  height: 94vh;
  margin: 3vh 2vw;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: auto;
  scrollbar-color: #000000 #f5f5f5;
  scrollbar-width: thin;
  padding: auto 2vw;

  @include media-breakpoint-down(sm) {
    height: 90vh;
    margin: 4vh inherit 6vh;
  }
}

.loading-screen-wrapper {
  z-index: 100000;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  color: $primary-colour;
  background-color: #ffffff;
}

.loading-screen-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h2 {
  text-align: center;
}

.align-center {
  text-align: center;
}