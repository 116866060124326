//Colour scheme

$primary-colour: #F26A38;	// Main Primary color */
$primary-colour-lighter: #FFA888;
$primary-colour-light: #FF8A5E;
$primary-colour-dark: #D74611;
$primary-colour-darker: #A83004;

$secondary-colour-1: #F2A238;	// Main Secondary color (1) */
$secondary-colour-1-lighter: #FFCC88;
$secondary-colour-1-light: #FFBA5E;
$secondary-colour-1-dark: #D78211;
$secondary-colour-1-darker: #A86204;

$secondary-colour-2: #2C6B9C;	// Main Secondary color (2) */
$secondary-colour-2-lighter: #71A2C8;
$secondary-colour-2-light: #4780AC;
$secondary-colour-2-dark: #13568A;
$secondary-colour-2-darker: #0A416C;

$complement-colour: #27A76D;	// Main Complement color */
$complement-colour-lighter: #6ECFA3;
$complement-colour-light: #44B783;
$complement-colour-dark: #0B9456;
$complement-colour-darker: #037441;

$light-grey: #888;
$black: #000;
$white: #fff;
$default-text-colour: $black;

$default-body-font: 'Open Sans', sans-serif;
$default-header-font: 'Ubuntu', sans-serif;